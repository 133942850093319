import { IoIosArrowDown } from "react-icons/io"
import * as S from "../styles"
import { useWhatsApp } from "../../../contexts/WhatsAppProvider"
import { AiFillCloseCircle } from "react-icons/ai"

export function InitialStep({ handleNextStep, closeChat }) {
  const { information } = useWhatsApp()

  const prodNome =
    information?.prod_nome.length > 16 ? `${information?.prod_nome.slice(0, 16)}...` : information?.prod_nome

  return (
    <S.Container>
      <S.ButtonClose onClick={() => closeChat()}>
        <AiFillCloseCircle size={32} />
      </S.ButtonClose>
      <S.HeaderContent>
        <h2>Whatsapp Innovation Brindes!</h2>
        <h3>Tire suas Dúvidas agora!</h3>
        <div className="hero-image-content">
          <img src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/HALFTONE.png" alt="HALFTONE" />
        </div>
        <div className="hero-image-seller">
          <img src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/VENDEDOR.png" alt="" />
        </div>
        <S.RadiusComponent />
      </S.HeaderContent>
      <S.Content>
        <S.MessageContent>
          <span className="msg-bolder">Olá, tudo bem?</span>
        </S.MessageContent>

        <S.MessageContent>
          <span>
            Vi que você está com uma dúvida sobre o item{" "}
            <strong>
              {information?.prod_nome} COD.: {information?.codigo_produto}
            </strong>
          </span>
        </S.MessageContent>

        <S.MessageContent>
          <span>
            {" "}
            Fico feliz em ajudar! Para ser mais assertivo,{" "}
            <span className="msg-bolder content">poderia me contar qual é a sua duvida</span> em relação a esse produto?
            Estou aqui para esclarecer tudo que você precisa saber!
          </span>
        </S.MessageContent>

        <S.MessageHelper onClick={() => handleNextStep()} className="mb-2">
          <span> Qual seria a sua</span> <span>dúvida?</span>
          <S.Icon>
            <IoIosArrowDown />
          </S.Icon>
        </S.MessageHelper>
      </S.Content>
    </S.Container>
  )
}
