import * as S from "../styles"
import { useForm, Controller } from "react-hook-form"
import { InputGroup, Input as StyledInput } from "../components/Input/styles"
import { Input } from "../components/Input"
import InputMask from "react-input-mask"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQuery } from "react-query"
import axios from "axios"
import { useDebounce } from "../../../hooks/useDebounce"
import { setFirstLetterUpperCase } from "../../../utils/setFirstLetterUpperCase"
import { useWhatsApp } from "../../../contexts/WhatsAppProvider"
import { SalvarContatoSite } from "../../../services/api/index"
import { AiFillCloseCircle } from "react-icons/ai"
import { validateCpfCnpj } from "../../../utils/validations/isValidCpfCnpj"
import { getDataLayer } from "../../../services/googleTagManager"
import { Select } from "../components/Select"
import { useVerbaCliente } from "../../../hooks/useVerbaCliente"

const schema = yup.object().shape({
  cpf_cnpj: yup.string().required("Campo obrigatório"),
  name: yup.string().required("Campo obrigatório"),
  telefone: yup
    .string()
    .transform((value) =>
      value?.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "").replaceAll(" ", ""),
    )
    .required("Campo obrigatório")
    .min(11, "Telefone inválido"),

  email: yup.string().email("Email inválido").required("Campo obrigatório"),
  id_verba: yup.string().required("Campo obrigatório"),
})

export function FinallyStep({ selectedOption, handleNextStep, closeChat, handlePreviousStep }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const { information } = useWhatsApp()

  const cpfCnpj = watch("cpf_cnpj")?.replace(/\D/g, "")

  const debouncedCpfCnpj = useDebounce(cpfCnpj, 500)

  const { data } = useQuery(
    ["razao_social", debouncedCpfCnpj],
    async () => {
      const response = await axios.get(
        `https://api.innovationbrindes.com.br/api/site/v2/pedido/busca-dados-receita/${debouncedCpfCnpj}`,
      )

      return response.data
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1, // 1 minuto
      enabled: !!debouncedCpfCnpj,
      onSuccess: (data) => {
        setValue("razao_social", setFirstLetterUpperCase(data?.RAZAO_SOCIAL?.replace(/[^a-zA-Z ]/g, ""))?.trim())
      },
    },
  )

  const formattedRazaoSocial = setFirstLetterUpperCase(data?.RAZAO_SOCIAL?.replace(/[^a-zA-Z ]/g, ""))?.trim()

  async function onSubmitDataWhatsapp(data) {
    if (cpfCnpj.length >= 14 && !data.razao_social) {
      return setError("razao_social", {
        type: "manual",
        message: "Campo obrigatório",
      })
    }

    const isCpfCnpjValid = validateCpfCnpj(data.cpf_cnpj)

    const isCnpj = data.cpf_cnpj.length >= 14

    if (!isCpfCnpjValid) {
      return setError("cpf_cnpj", {
        type: "manual",
        message: "CPF / CNPJ inválido",
      })
    }

    handleNextStep()

    const params = {
      ...data,
      msg_id: selectedOption.map((item) => item.id),
      nome_parceiro: data.name,
      razao_social: formattedRazaoSocial?.trim(),
      cpf_cnpj_parceiro: data.cpf_cnpj.replace(/\D/g, ""),
      telefone_parceiro: data.telefone,
      email_parceiro: data.email,
      codigo_produto: information?.codigo_produto,
    }

    const whatsappDataLayer = getDataLayer({
      event: "whatsappClientData",
      category: "whatsapp",
      action: "click",
      label: "whatsappClientData",
      value: params,
    })

    try {
      const response = await SalvarContatoSite.post("", params)

      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

      //551126496030
      //551126496030
      if (isMobile) {
        window.location.href = `https://api.whatsapp.com/send?phone=551126496030&text= tudo bem? Meu nome é ${data.name} e gostaria de saber mais sobre os produtos da Innovation Brindes. 
        `
      } else {
        window.open(
          `https://api.whatsapp.com/send?phone=551126496030&text= tudo bem? Meu nome é ${data.name} e gostaria de saber mais sobre os produtos da Innovation Brindes. 
          
`,
          "_blank",
        )
      }
    } catch (error) {
      console.log(error)
    }

    reset()

    closeChat()
  }

  const hasCnpj = cpfCnpj?.length >= 14

  const { verbaCliente, isLoading } = useVerbaCliente()

  return (
    <S.Container>
      <S.ButtonClose onClick={() => closeChat()}>
        <AiFillCloseCircle size={32} />
      </S.ButtonClose>
      <S.HeaderFinallyContent>
        <div className="hero-image-content">
          <img src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/HALFTONE.png" alt="HALFTONE" />
        </div>
        <div className="hero-image-seller">
          <img src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/VENDEDOR.png" alt="" />
        </div>
        <S.RadiusComponent />
      </S.HeaderFinallyContent>
      <S.FinallyContent>
        <S.MessageContent>
          <span className="light">
            <span className="msg-bolder content">Perfeito! </span>
            Agora, para que possamos oferecer um atendimento personalizado, peço que gentilmente preencha os dados
            abaixo. Assim, você terá um atendimento imediato com um de nossos consultores especializados
          </span>
        </S.MessageContent>
        <S.FormContent onSubmit={handleSubmit(onSubmitDataWhatsapp)}>
          <Controller
            name="cpf_cnpj"
            control={control}
            onChange={(e) => e[0].target.value}
            render={({ field }) => (
              <InputGroup error={errors.cpf_cnpj?.message}>
                <label htmlFor="">CPF / CNPJ:</label>
                <S.CustomizableInputCnpj
                  {...field}
                  onChange={(e, type) => {
                    field.onChange(e.target.value)
                  }}
                />
              </InputGroup>
            )}
          />
          {hasCnpj && (
            <Input
              label="RAZÃO SOCIAL"
              {...register("razao_social")}
              id="razao_social"
              type="text"
              value={formattedRazaoSocial}
              error={errors.razao_social?.message}
            />
          )}
          <Input label="NOME" {...register("name")} id="name" type="text" error={errors.name?.message} />
          <InputMask mask="(99) 99999-9999" maskChar="_" {...register("telefone")}>
            {({ inputRef, ...rest }) => (
              <InputGroup error={errors.telefone?.message}>
                <label htmlFor="">TELEFONE:</label>
                <StyledInput {...rest} ref={inputRef} />
              </InputGroup>
            )}
          </InputMask>
          <Input label="EMAIL" {...register("email")} id="email" type="email" error={errors.email?.message} />

          <Select
            placeholder="Orçamento para seus brindes"
            options={verbaCliente}
            {...register("id_verba")}
            error={errors.id_verba?.message}
          />

          <S.ButtonOptionSend maxWidth="256px" alignSelf="center" fontWeight="bold" type="submit">
            Iniciar atendimento
          </S.ButtonOptionSend>

          <button className="underline text-gray-500 text-sm" onClick={handlePreviousStep}>
            Voltar
          </button>
        </S.FormContent>
      </S.FinallyContent>
    </S.Container>
  )
}
