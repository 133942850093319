import styled from "styled-components"

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  padding-block: 5px;
  padding-inline: 13px;
  border: 1px solid #707070;
  border-radius: 16px;

  overflow: hidden;

  ${(props) =>
    props.error &&
    `
    border: 1px solid #FF4F00;
  `}

  label {
    font-size: 13px;
    color: #707070;
    font-weight: bold;
    white-space: nowrap;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    label {
      font-size: 10px;
    }

    padding-block: 1px;
  }
`

export const Input = styled.input`
  outline: none;
  border: none;

  padding-left: 10px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};

  @media (max-width: 1366px) and (min-width: 900px) {
    max-width: 150px;
  }
`
