import * as S from "../styles"
import { useForm, Controller } from "react-hook-form"
import { InputGroup, Input as StyledInput } from "../components/Input/styles"
import { Input } from "../components/Input"
import InputMask from "react-input-mask"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQuery } from "react-query"
import axios from "axios"
import { useDebounce } from "../../../hooks/useDebounce"
import { setFirstLetterUpperCase } from "../../../utils/setFirstLetterUpperCase"
import { SalvarContatoSite } from "../../../services/api/index"
import { AiFillCloseCircle } from "react-icons/ai"
import { validateCpfCnpj } from "../../../utils/validations/isValidCpfCnpj"
import { getDataLayer } from "../../../services/googleTagManager"
import { Select } from "../components/Select"
import { useVerbaCliente } from "../../../hooks/useVerbaCliente"

const schema = yup.object().shape({
  cpf_cnpj: yup.string().required("Campo obrigatório"),
  name: yup.string().required("Campo obrigatório"),
  //retira a mascara do telefone para validar
  telefone: yup
    .string()
    .transform((value) =>
      value?.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "").replaceAll(" ", ""),
    )
    .required("Campo obrigatório")
    .min(11, "Telefone inválido"),

  email: yup.string().email("Email inválido").required("Campo obrigatório"),
  id_verba: yup.string().required("Campo obrigatório"),
  //tem que ser obrigado razao_social se o cpf_cnpj for cnpj
})

export function HomeStep({ handleLastStep, closeChat }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const cpfCnpj = watch("cpf_cnpj")?.replace(/\D/g, "")

  const debouncedCpfCnpj = useDebounce(cpfCnpj, 500)

  const { data } = useQuery(
    ["razao_social", debouncedCpfCnpj],
    async () => {
      const response = await axios.get(
        `https://api.innovationbrindes.com.br/api/site/v2/pedido/busca-dados-receita/${debouncedCpfCnpj}`,
      )

      return response.data
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1, // 1 minuto
      enabled: !!debouncedCpfCnpj,
      onSuccess: (data) => {
        setValue("razao_social", setFirstLetterUpperCase(data?.RAZAO_SOCIAL?.replace(/[^a-zA-Z ]/g, ""))?.trim())
      },
    },
  )

  const formattedRazaoSocial = setFirstLetterUpperCase(data?.RAZAO_SOCIAL?.replace(/[^a-zA-Z ]/g, ""))?.trim()

  async function onSubmitDataWhatsapp(data) {
    //se tiver cnpj > 14 digitos precisa ter razao_social
    if (cpfCnpj.length >= 14 && !data.razao_social) {
      return setError("razao_social", {
        type: "manual",
        message: "Campo obrigatório",
      })
    }

    const isCpfCnpjValid = validateCpfCnpj(data.cpf_cnpj)

    const isCnpj = data.cpf_cnpj.length >= 14

    if (!isCpfCnpjValid) {
      return setError("cpf_cnpj", {
        type: "manual",
        message: "CPF / CNPJ inválido",
      })
    }

    handleLastStep()

    const params = {
      ...data,
      nome_parceiro: data.name,
      razao_social: formattedRazaoSocial?.trim(),
      cpf_cnpj_parceiro: data.cpf_cnpj.replace(/\D/g, ""),
      telefone_parceiro: data.telefone,
      email_parceiro: data.email,
    }

    try {
      const response = await SalvarContatoSite.post("", params)

      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

      const whatsappDataLayer = getDataLayer({
        event: "whatsappClientData",
        category: "whatsapp",
        action: "click",
        label: "whatsappClientData",
        value: {
          ...params,
          telefone_parceiro: `+55${data.telefone.replace(/\D/g, "")}`,
        },
      })

      if (isMobile) {
        window.location.href = `https://api.whatsapp.com/send?phone=551126496030&text= tudo bem? Meu nome é ${data.name} e gostaria de saber mais sobre os produtos da Innovation Brindes.. `
      } else {
        window.open(
          `https://api.whatsapp.com/send?phone=551126496030&text= tudo bem? Meu nome é ${data.name} e gostaria de saber mais sobre os produtos da Innovation Brindes. 
  `,
          "_blank",
        )
      }
    } catch (error) {
      console.log(error)
    }

    reset()

    closeChat()
  }

  const hasCnpj = cpfCnpj?.length >= 14

  const { verbaCliente, isLoading } = useVerbaCliente()

  return (
    <S.Container>
      <S.ButtonClose onClick={() => closeChat()}>
        <AiFillCloseCircle size={32} />
      </S.ButtonClose>

      <S.HeaderHomeContent>
        <h2>Whatsapp Innovation Brindes!</h2>
        <h3>Tire suas Dúvidas agora!</h3>
        <div className="hero-image-content">
          <img src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/HALFTONE.png" alt="HALFTONE" />
        </div>
        <div className="hero-image-seller">
          <img src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/VENDEDOR.png" alt="" />
        </div>
        <S.RadiusComponent />
      </S.HeaderHomeContent>

      <S.HomeContent>
        <S.MessageContentHome className="text-sm">Olá, tudo bem?</S.MessageContentHome>
        <S.MessageContentHome isParagraph className="text-sm">
          Irei fazer seu atendimento agora. Tudo o que você precisa fazer é apenas preencher os dados abaixo e esterei à
          sua disposição para ajuda-lo
        </S.MessageContentHome>
        <S.FormContentHome onSubmit={handleSubmit(onSubmitDataWhatsapp)}>
          <Controller
            name="cpf_cnpj"
            id="cpf_cnpj"
            control={control}
            onChange={(e) => e[0].target.value}
            render={({ field }) => (
              <InputGroup error={errors.cpf_cnpj?.message}>
                <label htmlFor="cpf_cnpj">CPF / CNPJ:</label>
                <S.CustomizableInputCnpj
                  {...field}
                  onChange={(e, type) => {
                    field.onChange(e.target.value)
                  }}
                  id="cpf_cnpj"
                  name="cpf_cnpj"
                />
              </InputGroup>
            )}
          />
          {hasCnpj && (
            <Input
              label="RAZÃO SOCIAL"
              {...register("razao_social")}
              id="razao_social"
              type="text"
              value={formattedRazaoSocial}
              error={errors.razao_social?.message}
              maxWidth="106px"
            />
          )}
          <Input label="NOME" {...register("name")} id="name" type="text" error={errors.name?.message} />
          <InputMask id="telefone" name="telefone" mask="(99) 99999-9999" maskChar="_" {...register("telefone")}>
            {({ inputRef, ...rest }) => (
              <InputGroup error={errors.telefone?.message}>
                <label htmlFor="telefone">TELEFONE:</label>
                <StyledInput {...rest} ref={inputRef} />
              </InputGroup>
            )}
          </InputMask>
          <Input label="EMAIL" {...register("email")} id="email" type="email" error={errors.email?.message} />

          <Select
            placeholder="Orçamento para seus brindes"
            options={verbaCliente}
            {...register("id_verba")}
            error={errors.id_verba?.message}
          />

          <S.ButtonOptionSend
            isHome
            maxWidth="256px"
            alignSelf="center"
            fontWeight="bold"
            type="submit"
            className="mb-2"
          >
            Iniciar atendimento
          </S.ButtonOptionSend>
        </S.FormContentHome>
      </S.HomeContent>
    </S.Container>
  )
}
