import { AiFillCloseCircle } from "react-icons/ai"
import { OptionsList } from "../OptionsList"
import * as S from "../styles"

const listOptions = [
  {
    id: 1,
    title: "Informações do produto",
  },
  {
    id: 2,
    title: "Tipo de personalização",
  },
  {
    id: 3,
    title: "Envio da logomarca / arte",
  },
  {
    id: 4,
    title: "Quantidade mínima",
  },
  {
    id: 5,
    title: "Prazo de produção",
  },
  {
    id: 7,
    title: "Frete",
  },
  {
    id: 8,
    title: "Forma de pagamento",
  },
  {
    id: 9,
    title: "Fazer um orçamento",
  },
  {
    id: 10,
    title: "Fechar um pedido",
  },
  {
    id: 11,
    title: "Outros",
  },
]

export function OptionStep({ selectedOption, handleSelectedOption, handleNextStep, closeChat }) {
  return (
    <S.Container>
      <S.ButtonClose onClick={() => closeChat()}>
        <AiFillCloseCircle size={32} />
      </S.ButtonClose>
      <S.HelperContainer>
        <S.ImageHelper>
          <img id="img-1" src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/HALFTONE.png" alt="" />
          <img id="img-2" src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/HALFTONE.png" alt="" />
        </S.ImageHelper>

        <S.HelperContent>
          <h1>Qual seria a sua </h1>
          <h1>dúvida?</h1>

          <S.OptionsContent>
            {listOptions.map((option) => (
              <OptionsList
                key={option.id}
                option={option}
                selectedOption={selectedOption}
                handleSelectedOption={handleSelectedOption}
              />
            ))}

            <S.ButtonOptionSend onClick={handleNextStep} disabled={selectedOption.length === 0}>
              Enviar
            </S.ButtonOptionSend>
          </S.OptionsContent>
        </S.HelperContent>
      </S.HelperContainer>
    </S.Container>
  )
}
