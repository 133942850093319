import styled, { keyframes } from "styled-components"
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask"
import { Button } from "@chakra-ui/button"
import * as Checkbox from "@radix-ui/react-checkbox"

export const CustomizableInputCnpj = styled(CpfCnpj)`
  outline: none;
  border: none;

  padding-left: 10px;
`

export const WhatsAppButton = styled(Button)`
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes pulse-border {
    0% {
      transform: scale(1);
      opacity: 0.75;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 0.75;
    }
  }

  .pulse-button {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }

  .pulse-button:before {
    animation-name: pulse-border;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
`

export const Container = styled.div`
  max-width: 354px;
  width: 100%;
  max-height: 747px;
  height: 100%;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  z-index: 9999;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);

  -webkit-font-smoothing: antialiased;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    height: 550px;
    width: 259px;
  }
`

export const HeaderContent = styled.div`
  height: 35%;
  width: 100%;
  background: #95c620;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 9px;

  overflow: hidden;

  h2 {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    margin-top: 9px;
  }

  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 22px;
    background: #ff4f00;
    padding-inline: 11px;
    padding-block: 9px;
    max-width: fit-content;
    border-radius: 9px;
    font-weight: bold;
  }

  .hero-image-content {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .hero-image-seller {
    position: relative;
    z-index: 10;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    h2,
    h3 {
      font-size: 14px;
    }

    .hero-image-seller {
      width: 118px;
      height: 118px;
    }

    .hero-image-content {
      top: 60px;
    }
  }
`

export const HeaderHomeContent = styled.div`
  height: 32%;
  width: 100%;
  background: #95c620;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 9px;

  overflow: hidden;

  h2 {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    margin-top: 9px;
  }

  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 22px;
    background: #ff4f00;
    padding-inline: 11px;
    padding-block: 9px;
    max-width: fit-content;
    border-radius: 9px;
    font-weight: bold;
  }

  .hero-image-content {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .hero-image-seller {
    position: relative;
    z-index: 10;
    top: -17px;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    h2,
    h3 {
      font-size: 14px;
    }

    .hero-image-seller {
      width: 118px;
      height: 118px;
      top: -17px;
    }

    .hero-image-content {
      top: 60px;
    }
  }
`

export const HeaderFinallyContent = styled.div`
  height: 20%;
  width: 100%;
  background: #95c620;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 9px;

  overflow: hidden;

  h2 {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    margin-top: 9px;
  }

  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 22px;
    background: #ff4f00;
    padding-inline: 11px;
    padding-block: 9px;
    max-width: fit-content;
    border-radius: 9px;
    font-weight: bold;
  }

  .hero-image-content {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .hero-image-seller {
    position: relative;
    z-index: 10;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    .hero-image-seller {
      width: 118px;
      height: 118px;
      margin-top: 10px;
    }
    height: 22%;
  }
`

export const RadiusComponent = styled.div`
  position: absolute;
  bottom: -150px;
  left: -30px;
  width: 410px;
  height: 200px;
  background: #fff;
  border-radius: 50%;
  z-index: 1;

  @media (max-width: 1366px) and (min-width: 900px) {
    left: -72px;
  }
`

export const Content = styled.div`
  padding-inline: 15px;

  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 1366px) and (min-width: 900px) {
    gap: 12px;
  }
`

export const MessageContent = styled.div`
  padding-inline: 14px;
  padding-block: 3px;

  border: 1px solid #707070;
  border-radius: 16px;

  span {
    color: #707070;
    font-size: 16px;
  }

  .light {
    font-size: 16px;
    line-height: 1;
  }

  .msg-bolder {
    font-weight: bold;
    font-size: 22px;
  }

  .msg-bolder.content {
    font-size: 16px;
  }

  strong {
    color: #95c620;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    span {
      font-size: 12px;
    }

    .msg-bolder {
      font-size: 12px;
    }

    .msg-bolder.content {
      font-size: 12px;
    }

    .light {
      font-size: 12px;
    }

    line-height: 1;
  }
`

export const MessageContentHome = styled.div`
  padding-inline: 14px;
  padding-block: 9px;

  border: 1px solid #707070;
  border-radius: 16px;

  span {
    color: #707070;
    font-size: 16px;
  }

  .light {
    font-size: 18px;
  }

  .msg-bolder {
    font-weight: bold;
    font-size: 22px;
  }

  .msg-bolder.content {
    font-size: 16px;
  }

  strong {
    color: #95c620;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    span {
      font-size: 14px;
    }

    .msg-bolder {
      font-size: 14px;
    }

    .msg-bolder.content {
      font-size: 14px !important;
    }

    .light {
      font-size: 14px;
    }

    line-height: 1.3;
    font-size: ${({ isParagraph }) => (isParagraph ? "13px!important" : "14px!important")};
    padding-block: 7px;
    color: ${({ isParagraph }) => (isParagraph ? "#707070!important" : "")};
  }
`

export const MessageHelper = styled.div`
  width: 100%;
  -webkit-box-shadow: 10px 6px 31px -18px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 6px 31px -18px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 6px 31px -18px rgba(0, 0, 0, 0.75);
  font-size: 16px;
  font-weight: bold;
  color: #707070;

  background: #f5f5f5;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding-block: 5px;

  border-radius: 16px;

  position: relative;

  margin-top: 20px;
  cursor: pointer;

  @media (max-width: 1366px) and (min-width: 900px) {
    margin-top: 0;
    font-size: 15px;
  }
`

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  right: 10px;

  svg {
    color: #95c620;
    font-size: 24px;
  }
`

export const HelperContainer = styled.div`
  background: #95c620;
  width: 100%;
  height: 100%;

  position: relative;
  padding: 15px;
`

export const FinallyContent = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  padding: 15px;

  @media (max-width: 1366px) and (min-width: 900px) {
    padding: 0 15px;

    transform: translateY(-25px);
    z-index: 10;
    background: #fff;
  }
`

export const HomeContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  position: relative;
  padding: 15px;

  @media (max-width: 1366px) and (min-width: 900px) {
    padding: 0 15px;

    z-index: 10;
    background: #fff;
    gap: 5px;
  }
`

export const ImageHelper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: 0;

  img {
    width: 400px;
  }

  #img-1 {
    transform: translateX(160px);
  }

  #img-2 {
    transform: translateX(-160px);
  }
`

export const HelperContent = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  position: relative;
  z-index: 10;

  border-radius: 16px;
  padding: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  h1 {
    font-size: 18px;
    color: #707070;
    font-weight: bold;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    h1 {
      font-size: 16px;
    }
  }
`

export const OptionsContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  margin-top: 60px;

  @media (max-width: 1366px) and (min-width: 900px) {
    margin-top: 5px;
    gap: 8px;
  }
`

export const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.border-bottom-feat {
    border-bottom: 1px solid #707070;
    padding-bottom: 10px;
  }

  span {
    color: #707070;
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    span {
      font-size: 11px;
    }

    &.border-bottom-feat {
      padding-bottom: 8px;
    }
  }
`

export const CheckboxRoot = styled(Checkbox.Root)`
  width: 25px;
  height: 25px;
  border: 1px solid #707070;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #ff4f00;
    font-weight: bold;
  }

  ${(props) =>
    props.checked &&
    `
    border-color: #ff4f00;
  `}

  @media (max-width: 1366px) and (min-width: 900px) {
    width: 20px;
    height: 20px;
  }
`

export const ButtonOptionSend = styled.button`
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  outline: none;
  border: none;
  background: #95c620;
  border-radius: 23px;
  color: #fff;
  font-size: 16px;
  padding: ${({ isHome }) => (isHome ? "10px 0" : "18px")};

  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};

  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};

  &:disabled {
    background: #d5d5d5;
    cursor: not-allowed;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    padding: 7px;
  }
`

export const FormContent = styled.form`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  select {
    padding-block: 5px;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    select {
      font-size: 10px;
    }
    gap: 5px;
  }
`

export const FormContentHome = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 1366px) and (min-width: 900px) {
    gap: 5px;
    margin-top: 0;

    select {
      font-size: 10px;

      height: 30px;
    }
  }
`

export const ContainerOpen = styled.div`
  opacity: ${(props) => (props.open ? "1" : "0")};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  transition: all 0.3s ease-in-out;

  transform: ${(props) => (props.open ? "translateX(0)" : "translateX(100%)")};

  height: 746px;
  width: 354px;

  position: fixed;
  bottom: 10px;
  right: 10px;

  z-index: 9999;

  //maxwidth 1366px and minwidth 768px
  @media (max-width: 1366px) and (min-width: 900px) {
    height: 550px;
    width: 259px;
  }

  @media (max-width: 768px) {
    background: #fff;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
`

export const ButtonClose = styled.button`
  outline: none;
  border: none;
  background: transparent;
  position: absolute;
  top: -50px;
  right: 0px;

  svg {
    color: #707070;
  }

  //min width 768px e max width 1366px

  @media (max-width: 768px) {
    top: -15px;
    right: -15px;
    z-index: 999910230;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    width: 37px;
    height: 37px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1366px) and (min-width: 900px) {
    top: -50px;
    right: 10px;
  }
`

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg)
  }

`

export const Loading = styled.div`
  padding-top: 73px;
  max-width: 254px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;

  h1 {
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
  }
  h3 {
    color: #fff;
    font-size: 24px;
    text-align: center;
    font-weight: normal;
    padding-top: 50px;
  }

  svg {
    color: #fff;
    //animation infinite sem quebra forwadrs
    animation: ${rotate} 2s forwards infinite;
  }

  p {
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
    span {
      font-weight: bold;
    }
  }
`

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #95c620;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;

  position: relative;
  right: 10px;
  bottom: 10px;
`
