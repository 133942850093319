import { useState } from "react"
import * as S from "./styles"
import { BsCheck } from "react-icons/bs"

export function OptionsList({ option, handleSelectedOption }) {
  const [checked, setChecked] = useState(false)

  function handleChecked() {
    setChecked((prevChecked) => !prevChecked)

    handleSelectedOption(option)
  }

  const last = option.id === 11

  return (
    <>
      <S.Option className={last ? "" : "border-bottom-feat"}>
        <span>{option.title}</span>
        <S.CheckboxRoot checked={checked} onClick={handleChecked}>
          {checked ? <BsCheck className="w-full" size={32} /> : ""}
        </S.CheckboxRoot>
      </S.Option>
    </>
  )
}
