import { forwardRef } from "react"
import * as S from "./styles"

const InputBase = ({ label, name, type, ...rest }, ref) => {
  return (
    <S.InputGroup error={rest.error}>
      <label htmlFor={name}>{label}:</label>
      <S.Input name={name} type={type} ref={ref} {...rest} />
    </S.InputGroup>
  )
}

export const Input = forwardRef(InputBase)
