import { useEffect, useState } from "react"

import { FinallyStep, InitialStep, OptionStep, HomeStep } from "./Steps"
import { ContainerOpen } from "./styles"
import { useWhatsApp } from "../../contexts/WhatsAppProvider"
import { LoadingStep } from "./Steps/LoadingStep"

export function WhatsAppChat({ isHomePage = false }) {
  const [step, setStep] = useState(0)
  const [selectedOption, setSelectedOption] = useState([])

  const { openChat, information, setOpenChat } = useWhatsApp()

  useEffect(() => {
    if (information) {
      setStep(0)
    }
  }, [information])

  function closeChat() {
    setOpenChat(false)
    setStep(0)
    setSelectedOption([])
  }

  function handleNextStep() {
    setStep((prevStep) => prevStep + 1)
  }

  function handlePreviousStep() {
    if (step === 0) {
      return closeChat()
    }
    setStep((prevStep) => prevStep - 1)
    setSelectedOption([])
  }

  function handleLastStep() {
    setStep(3)
  }

  function handleSelectedOption(option) {
    if (selectedOption.includes(option)) {
      return setSelectedOption((prevSelectedOption) => prevSelectedOption.filter((item) => item.id !== option.id))
    }

    setSelectedOption([...selectedOption, option])
  }

  if (isHomePage || !information) {
    return (
      openChat && (
        <ContainerOpen open={openChat}>
          {step !== 3 && (
            <HomeStep selectedOption={selectedOption} handleLastStep={handleLastStep} closeChat={closeChat} />
          )}

          {step === 3 && <LoadingStep />}
        </ContainerOpen>
      )
    )
  }

  return (
    openChat && (
      <ContainerOpen open={openChat}>
        {step === 0 && <InitialStep handleNextStep={handleNextStep} closeChat={closeChat} />}
        {step === 1 && (
          <OptionStep
            selectedOption={selectedOption}
            handleSelectedOption={handleSelectedOption}
            handleNextStep={handleNextStep}
            closeChat={closeChat}
          />
        )}
        {step === 2 && (
          <FinallyStep
            selectedOption={selectedOption}
            handleNextStep={handleNextStep}
            closeChat={closeChat}
            handlePreviousStep={handlePreviousStep}
          />
        )}

        {step === 3 && <LoadingStep />}
      </ContainerOpen>
    )
  )
}
