import { AiOutlineLoading3Quarters } from "react-icons/ai"
import { Loading, LoadingContainer } from "../styles"

export function LoadingStep() {
  return (
    <LoadingContainer>
      <Loading>
        <AiOutlineLoading3Quarters size={82} />
        <h1>Aguarde um momento por favor</h1>
        <h3>Seu atendimento está prestes a ser iniciado!</h3>
      </Loading>
    </LoadingContainer>
  )
}
