import { forwardRef } from "react"
import { Select as ChakraSelect } from "@chakra-ui/react"

export const Select = forwardRef((props, ref) => {
  return (
    <ChakraSelect
      placeholder={props.placeholder || "Orçamento para seus brindes"}
      sx={{
        ".chakra-select__option": {
          color: "black",
        },
        display: "flex",
        alignItems: "center",
        paddingInline: "13px",
        border: "1px solid #707070",
        borderRadius: "16px",
        fontSize: "13px",
        color: "#707070",
        fontWeight: "bold",
        textTransform: "uppercase",
      }}
      _focus={{
        borderColor: "#707070",
      }}
      borderColor={!props.error ? "#707070" : "red.500"}
      ref={ref}
      {...props}
    >
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </ChakraSelect>
  )
})
